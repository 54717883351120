<template>
  <div class="section-content pa-0 mt-n1">
    <div class="primary-section-content mr-6" v-if="true">
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

      <hb-data-table-header
        :left-columns="hasPermission('create_templates') ? '9' : '12'"
        :right-columns="hasPermission('create_templates') ? '3' : '0'"
      >
        <template v-slot:description>
          <span v-if="!isOPS">You can set up a default residential lease and default storage lease for each property. Set them up here, and then you can assign them to each property. You can also set a default lease that will be used for all properties.</span>
        </template>
        <template v-slot:actions>
          <hb-btn color="secondary" small @click="showDialog = true" v-if="hasPermission('create_templates')">Add New Configuration</hb-btn>
        </template>
      </hb-data-table-header>

      <hb-data-table
        :headers="headers"
        :items="templates"
        @click:row="editItem"
      >
        <template v-slot:item.name="{ item }">
          {{item.name}}<br />
          <span class="light-text">{{item.description}}</span>
        </template>
        <template v-slot:item.type="{ item }">{{ item.unit_type | capitalize  }}</template>
        <template v-slot:item.default="{ item }">{{item.is_default? 'Yes': 'No' }}</template>
        <template v-if="hasPermission('view_edit_templates') || hasPermission('delete_templates')" v-slot:item.actions="{ item }">
          <hb-menu
            options
            align-right
          >
            <v-list>
              <v-list-item v-if="hasPermission('view_edit_templates')" @click="editItem(item)">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('delete_templates')" @click="deleteItem(item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </hb-menu>
        </template>
      </hb-data-table>

      <new-lease-dialog v-model="showDialog" v-if="showDialog" @close="closeDialog" @saved="fetchData" :leaseTemeplateId="template_id" :isOPS="isOPS"></new-lease-dialog>
      <!-- <new-lease-dialog v-model="template_id" v-if="template_id" @close="closeDialog" @saved="fetchData" :leaseTemeplateId="template_id"></new-lease-dialog> -->

      <slide-out-window class="pt-0 default-props colored-box" id="lease-template-drawer" :class="{hide: !showAdd, 'top-205px' : !isscroll }"  @close="closeAdd" >
        <manage-template
          v-if="showAdd"
          :template="selected"
          slot="body"
          @close="closeWindow"
          @saved="fetchData"
        >
        </manage-template>
      </slide-out-window>


      <hb-modal v-if="showDelete" show-help-link v-model="showDelete" size="medium" title="Delete Lease Template" @close="closeWindow" confirmation>
        <template v-slot:content>
          <div class="py-4 px-6">
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            Are you sure you want to delete the selected lease template?
            <br /><br />
            This action cannot be undone.
          </div>
        </template>
        <template v-slot:actions>
          <hb-btn :disabled="isLoading('delete')" color="destructive" @click="deleteConfirm">Delete Template</hb-btn>
          <span v-show="isLoading('delete')" >
            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
          </span>
        </template>
      </hb-modal>
    </div>

    <!-- <new-lease-dialog v-else v-model="showDialog" @close="closeDialog" @saved="fetchData" :leaseTemeplateId="template_id"></new-lease-dialog> -->
    <!-- <configure-lease-template :leaseTemeplateId="template_id" @removeSelected="template_id = ''" v-else></configure-lease-template> -->
  </div>
</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import moment from 'moment';
    import DropdownMenu from '../assets/DropdownMenu.vue';
    import SlideOutWindow from '../includes/SlideOutWindow.vue';
    import ManageTemplate from './configureLease/Index.vue';
    import NewLeaseDailog from './configureLease/CreateNewLeaseDialog.vue';
    import { mapGetters } from "vuex";

    export default {
        name: "LeaseConfiguration",
        // Added by BCT 
        props: ['isOPS'],
        data() {
            return {

                templates:[],
                selected: {},
                showAdd: false,
                showDelete: false,
                showDialog : false,
                isscroll : false,
                windowColor : false,
                allowEditLease: false,
                template_id: '',
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Space Type", value: "type" },
                    { text: "Default", value: "default" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
            }
        },
        components:{
            Modal,
            Status,
            Loader,
            DropdownMenu,
            SlideOutWindow,
            ManageTemplate,
            'new-lease-dialog' : NewLeaseDailog,
            'configure-lease-template' : () => import('./configureLease/Index.vue')
        },
        created(){
            this.fetchData();
        },
        computed:{
          ...mapGetters({
            hasPermission: 'authenticationStore/rolePermission'
          })
        },
        filters:{
            formatDate(date){
                return moment(date).format('MM/DD/YYYY HH:mm:ss')
            },
            capitalize: function (value) {
              if (!value) return ''
              value = value.toString()
              return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        methods:{
            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.selected = {};
            },
            fetchData(){
                api.get(this, api.TEMPLATES).then(r => {
                    this.templates = r.templates;
                });
            },
            createNewTemplate(){
                this.selected = {};
                this.showAdd = true;

            },
            editItem(t){
                this.template_id = t.id;
                this.showDialog = true
                // this.$router.replace('/settings/templates/'+ t.id)
                // this.selected = t;
                // this.showAdd = true;

            },
            closeAdd(){
                this.showAdd = false;
                this.selected = {};
            },
            deleteItem(t){
                this.selected = t;
                this.showDelete = true;
            },
//            deleteItem(c){
//                this.selected = c;
//                this.showDelete = true;
//            },
            deleteConfirm(){

                api.delete(this, api.TEMPLATES, this.selected.id, 'delete').then( r => {
                    this.showDelete = false;
                    this.fetchData();
                })

//                api.post(this, api.TEMPL,  this.selected).then(r => {
//                    this.showDelete = false;
//                    this.fetchData();
//                    this.selected = {};
//                });

            },
            closeDialog(){
                this.template_id = ''
                this.showDialog = false
            },
            showSuccessMsg(name){
                this.successSet(this.$options.name, name + '" has been created');
            }
            // bindScrollListner(){
            //     let elem = document.getElementById('lease-template-drawer');
            //     let element = document.getElementsByClassName('content-view')[0];
            //     let lastScrollTop = 0;
            //     window.addEventListener("scroll", () => {
            //     let st = window.pageYOffset || document.documentElement.scrollTop;
            //     this.isscroll = true
            //     lastScrollTop = st <= 0 ? 0 : st;
            //     if (lastScrollTop == 0) {
            //         this.isscroll = false;
            //     }
            //     }, false);
            // }
        },
        beforeDestroy() {
            window.removeEventListener("scroll", function(){ console.log('event removed') })
        },
    }
</script>

<style scoped>
  .add-role-btn{
    font-size: 15px;
    text-decoration: none;
  }
  .add-role-btn:focus{
    border: none;
  }
  .template-table {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px rgba(11, 18, 29, 0.1), 0px 0px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
    border-radius: 4px;
  }
  .template-table .table-head{
    background: #FFFFFF;
    /* box-shadow: 0px 1px 0px rgba(11, 18, 29, 0.25); */
    border-bottom: 1px solid #ccc;
  }
  .template-table .table-head strong{
    color: #474F5A;
  }
  .template-table .table-row:hover:not(:first-child){
    background: #F9FAFB;
    cursor: pointer;
  }
  .template-table .table-row:first-child {
    cursor: inherit;
  }
  .template-table .table-row .table-cell{
    border-bottom: 1px solid #dce8ef;
    color: #000;
  }
  .light-text{
    color: #637381;
  }
  .not-visible{
    visibility: hidden;
  }
  .template-table .table-row:hover .not-visible{
    visibility: visible;
  }
  .top-205px{
    top: 205px !important;
    padding-bottom: 190px !important;
  }
  .default-props{
    top: 65px;
    padding-bottom: 50px;
    z-index: 11;
  }
  .colored-box{
    background: #F9FAFB;
  }

  @media (min-width: 1841px){
    .top-205px{
      top: 185px !important;
      padding-bottom: 170px !important;
    }
  }
</style>

<style>
  .subdued {
    color: #a5adb4;
    line-height: 18px;
  }
</style>
